import * as React from 'react';
import "./start.scss";
import Icon from "../../assets/svg/marble.svg";
import { MarbleHelmet } from '../components/shared/helmet';

const ContactPage = () => {
    return (
        <div className="signup-component">
            <MarbleHelmet
                pageName="Marble | Contact us"
                ogImage="https://marble-landing-site-assets.s3.eu-west-2.amazonaws.com/resume.png"
                ogTitle="Get in contact with Marble"
                ogURL="https://trymarble.co/contact"
            />
            <div className="icon-container full-screen">
                <img src={Icon} alt="Marble logo" /> <h1><a href="/">marble</a></h1>
            </div>
            <iframe id="typeform-full" width="100%" height="100%" frameBorder="0"
                    allow="camera; microphone; autoplay; encrypted-media;"
                    src="https://form.typeform.com/to/nJgshnpu?typeform-medium=embed-snippet" />
            <script type="text/javascript" src="https://embed.typeform.com/embed.js" />
        </div>
    )
}

export default ContactPage